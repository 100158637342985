import React from 'react';
import { Input, Button, Form } from 'antd';
import { SendOutlined } from '@ant-design/icons';

function ChatInput({ onSendMessage, isLoading }) {
  const [form] = Form.useForm(); // Verwenden Sie die Form-Instanz von Ant Design

  const onFinish = (values) => {
    if (!values.message.trim()) {
      return;
    }
    onSendMessage(values.message);
    form.resetFields(); // Setzt die Formularfelder nach dem Senden zurück
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="chatForm"
      style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }} // Stellt sicher, dass das Formular die volle Breite einnimmt
    >
      <Form.Item
        name="message"
        className="chatForm"
        style={{ flex: 1, marginRight: '8px', marginBottom: 0 }} // Passt das Styling des Form.Item an, um Abstände zu minimieren und Flex-Layout zu nutzen
        rules={[{ required: true, whitespace: true, message: 'Bitte eine Nachricht eingeben!' }]}
      >
        <Input.TextArea
          placeholder="Schreibe eine Nachricht..."
          autoSize={{ minRows: 1, maxRows: 6 }}
          // Entfernen oder setzen Sie disabled=false, damit Benutzer immer in der Lage sind, Text einzugeben
          className="chatInput"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey && !isLoading) {
              e.preventDefault(); // Verhindert den Zeilenumbruch bei Enter
              form.submit(); // Löst das Absenden des Formulars aus
            }
          }}
          style={{ borderRadius: '4px' }} // Fügt zusätzliche Styling-Attribute hinzu, um das ursprüngliche Aussehen zu bewahren
        />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        className="chatButton"
        disabled={isLoading}
        icon={<SendOutlined />}
        style={{ whiteSpace: 'nowrap', height: '40px' }} // Stellt sicher, dass der Button das gleiche Höhenstyling wie die Textarea hat
      >
      </Button>
    </Form>
  );
}

export default ChatInput;
