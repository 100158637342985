import React from 'react';
import { Menu, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const ThreadList = ({ threads, onSelectThread, selectedThreadId, onCreateThread }) => {
  const menuItems = threads.map(thread => ({
    key: thread.id.toString(),
    label: thread.name,
    onClick: () => onSelectThread(thread.id),
  }));

  return (
    <div className="threadListContainer">
      <Button
        onClick={onCreateThread}
        block
        icon={<PlusOutlined />}
        className="addThreadButton"
        style={{ marginBottom: '10px' }}
      >
        Neuer Thread
      </Button>
      <Menu
        className="threadsList"
        mode="inline"
        selectedKeys={[selectedThreadId ? selectedThreadId.toString() : '']}
        items={menuItems}
      />
    </div>
  );
};

export default ThreadList;
