import React, { useState, useEffect } from 'react';
import { Layout, Spin, Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import MessageList from './components/MessageList';
import ChatInput from './components/ChatInput';
import ThreadList from './components/ThreadList';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useTheme } from './ThemeContext';
import './index.css';
import { supabase } from './supabaseClient';
import {
  loadThreadsLogic,
  loadMessagesLogic,
  handleSelectThreadLogic,
  handleSendMessageLogic,
  handleCreateThreadLogic,
  subscribeToMessages,
  subscribeToThreadNameChanges,
  handleThreadNameChange
} from './appLogic';


const { Content, Sider } = Layout;

function App() {
  const theme = useTheme();
  const [threads, setThreads] = useState([]);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const isMobile = window.innerWidth < 768;

  const handleLoadingChange = (loadingStatus) => {
    setIsLoading(loadingStatus);
  };

  useEffect(() => {
    const currentThread = threads.find(thread => thread.id === selectedThreadId);
    if (currentThread) {
      document.title = currentThread.name + " | KindermannGPT";
    } else {
      document.title = "KindermannGPT";
    }
  }, [selectedThreadId, threads]);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (session) {
      loadThreadsLogic(setThreads);
    }
  }, [session]);

  useEffect(() => {
    loadMessagesLogic(selectedThreadId, setMessages);
  }, [selectedThreadId]);

  useEffect(() => {
    let unsubscribeFunc = () => { };
    if (selectedThreadId) {
      unsubscribeFunc = subscribeToMessages(selectedThreadId, setMessages);
    }
    return () => unsubscribeFunc();
  }, [selectedThreadId, setMessages]);

  useEffect(() => {
    const unsubscribe = subscribeToThreadNameChanges(selectedThreadId, () => handleThreadNameChange(setThreads));
    return () => unsubscribe && unsubscribe();
  }, [selectedThreadId]);

  const handleSelectThread = handleSelectThreadLogic(setSelectedThreadId, setMessages);

  const handleSendMessage = (message) => {
    handleSendMessageLogic(selectedThreadId, setSelectedThreadId, setThreads, setMessages, message, session);
  };

  const handleCreateThread = () => {
    handleCreateThreadLogic(setThreads, 'Neuer Thread', session, setSelectedThreadId);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (!session) {
    return (
      <div className="auth-wrapper">
        <div className="auth-form">
          <h1 className="auth-title">Anmelden</h1>
          <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} providers={[]} showLinks={false} localization={{
            variables: {
              sign_in: {
                email_label: 'E-Mail Adresse',
                password_label: 'Passwort',
                email_input_placeholder: 'user@kindermann.de',
                password_input_placeholder: 'Passwort eingeben',
                button_label: 'Anmelden',
              },
            },
          }} />
        </div>
      </div>
    );
  }

  return (
    <Layout className={`appLayout ${theme}`}>
      <Layout className="innerLayout">
        {isMobile && (
          <>
            <Button className="menuButton" type="primary" icon={<MenuOutlined />} onClick={toggleDrawer} />
            <Drawer
              title="Threads"
              placement="left"
              closable={true}
              onClose={toggleDrawer}
              visible={drawerVisible}
            >
              <ThreadList
                threads={threads}
                onSelectThread={handleSelectThread}
                selectedThreadId={selectedThreadId}
                onCreateThread={handleCreateThread}
              />
            </Drawer>
          </>
        )}
        {!isMobile && (
          <Sider className="sider sidebar threadSidebar">
            <ThreadList
              threads={threads}
              onSelectThread={handleSelectThread}
              selectedThreadId={selectedThreadId}
              onCreateThread={handleCreateThread}
              className="threadListContainer"
            />
          </Sider>
        )}
        <Layout className="contentLayout">
          <Content className="content">
            <MessageList messages={messages} onLoadingChange={handleLoadingChange} />
          </Content>
          <div className="chatInputContainer">
            <ChatInput onSendMessage={handleSendMessage} isLoading={isLoading} />
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default App;