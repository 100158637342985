import React from 'react';
import {
  LineChart, Line, BarChart, Bar, PieChart, Pie,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const ChartComponent = ({ data, chartType, chartConfig }) => {
  let safeData;

  const parseData = (input) => {
    if (typeof input === 'string') {
      try {
        const parsed = JSON.parse(input);
        // Zusätzliche Prüfung, ob das geparste Objekt wieder ein String ist (für doppelt kodierte JSONs)
        return typeof parsed === 'string' ? JSON.parse(parsed) : parsed;
      } catch (error) {
        console.error('Error parsing data:', error);
        return []; // Fallback zu einem leeren Array
      }
    } else if (typeof input === 'object') {
      // Eingabe ist bereits ein Objekt, keine Notwendigkeit zum Parsen
      return input;
    }
    console.error('Unsupported data type');
    return []; // Fallback für nicht unterstützte Datentypen
  };
  

  // Anwendung der parseData Funktion
  safeData = parseData(data);

  let config;
try {
  // Versuch, die Konfiguration zu parsen, falls sie ein String ist
  const parsedConfig = typeof chartConfig === 'string' ? JSON.parse(chartConfig) : chartConfig;
  // Anpassung, um sicherzustellen, dass wir die richtige Konfigurationsebene verwenden
  config = parsedConfig.chartConfig ? parsedConfig.chartConfig : parsedConfig;

  // Überprüfen, ob das Ergebnis ein Objekt ist
  if (typeof config !== 'object' || config === null) {
    throw new Error('Config is not a valid object');
  }
} catch (error) {
  console.error('Error parsing chartConfig:', error);
  config = {}; // Fallback zu einem leeren Objekt
}


  const getTickFormatter = (formatterKey) => {
    const formatters = {
      weekYearFormatter: (tick) => {
        const year = tick.substring(0, 4);
        const week = tick.substring(4, 6);
        return `KW${week}, ${year}`;
      }
    };
    return formatters[formatterKey] || ((tick) => tick);
  };

  const renderLines = () => {
    if (Array.isArray(config.line)) {
      return config.line.map((line) => (
        <Line
          key={line.dataKey}
          type="monotone"
          dataKey={line.dataKey}
          stroke={line.stroke}
        />
      ));
    }
    return null; // Gibt null zurück, wenn keine Linien konfiguriert sind
  };

  const renderBars = () => {
    // Überprüfen, ob config.bar existiert, bevor darauf zugegriffen wird
    if (config.bar && config.bar.dataKey) {
      return (
        <Bar
          key={config.bar.dataKey}
          dataKey={config.bar.dataKey}
          fill={config.bar.fill}
        />
      );
    }
    return null; // Gibt null zurück, wenn keine Balken konfiguriert sind
  };
  

  const renderChart = () => {
    const xAxisFormatter = config.xAxis && config.xAxis.tickFormatter ? getTickFormatter(config.xAxis.tickFormatter) : undefined;
  const xAxisDataKey = config.xAxis ? config.xAxis.dataKey : '';

    switch (chartType) {
      case 'LineChart':
      return (
        <LineChart data={safeData} margin={config.margin}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisDataKey} tickFormatter={xAxisFormatter} />
          <YAxis />
          <Tooltip />
          <Legend />
          {renderLines()}
        </LineChart>
      );
    case 'BarChart':
      return (
        <BarChart data={safeData} margin={config.margin}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisDataKey} tickFormatter={xAxisFormatter} />
          <YAxis />
          <Tooltip />
          <Legend />
          {renderBars()}
        </BarChart>
      );
      case 'PieChart':
        if(config.pie) {
          return (
            <PieChart>
              <Pie dataKey={config.pie.dataKey} data={safeData} fill={config.pie.fill} label />
              <Tooltip />
              <Legend />
            </PieChart>
          );
        }
        return <div>Missing pie chart configuration</div>;
      default:
        return <div>Unsupported chart type</div>;
    }
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      {renderChart()}
    </ResponsiveContainer>
  );
};

export default ChartComponent;
