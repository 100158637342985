import React, { useEffect, useRef, useState } from 'react';
import { List, Spin, Button, Tooltip, Typography, Image } from 'antd';
import { UserOutlined, DownloadOutlined } from '@ant-design/icons';
import ChartComponent from './ChartComponent'; // Import der ChartComponent
import Form from './Form'; // Importieren des Form-Komponenten
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import azunaLogo from '../assets/kindermann.svg';
import { supabase } from '../supabaseClient';




function MessageList({ messages, onLoadingChange }) {
  const bottomListRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messagesState, setMessagesState] = useState(messages);
  const [downloadUrls, setDownloadUrls] = useState({}); // Neu: Zustand für Download-URLs

  const isImageFile = (fileName) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(fileName);
  };

  
  const removeSourceReferences = (text) => {
    // Regex zum Finden der Quellenangaben, z.B. &#8203;``【oaicite:0】``&#8203;
    const regex = /【\d+†source】/g;
    // Entfernt alle Instanzen der Quellenangaben aus dem Text
    return text.replace(regex, '');
  };
  

  useEffect(() => {
    const generateSignedUrls = async () => {
      let urls = {};
      // Filtere nur Nachrichten vom Typ 'file'
      const fileMessages = messages.filter(msg => msg.type === 'file');

      for (let msg of fileMessages) {
        const filePath = msg.content; // Der Pfad zur Datei, gespeichert in msg.content

        // Erstelle eine signierte URL für die Datei
        const { data, error } = await supabase
          .storage
          .from('Files') // Bucket-Name
          .createSignedUrl(filePath, 60); 
        if (error) {
          console.error("Fehler beim Erstellen der signierten URL:", error);
        } else {
          // Speichere die signierte URL im 'urls'-Objekt, Schlüssel ist die Nachrichten-ID
          urls[msg.id] = data.signedUrl;
        }
      }

      // Aktualisiere den Zustand mit den generierten URLs
      setDownloadUrls(urls);
    };

    generateSignedUrls();
  }, [messages])

  // Methode zum Aktualisieren der Nachrichtendaten
  const updateMessageContent = (id, updatedContent) => {
    const updatedMessages = messagesState.map((msg) => {
      if (msg.id === id) {
        return { ...msg, content: JSON.stringify(updatedContent) };
      }
      return msg;
    });
    setMessagesState(updatedMessages);
  };


  useEffect(() => {
    if (bottomListRef.current) {
      bottomListRef.current.scrollIntoView({ behavior: 'auto' });
    }
    const lastMessage = messages[messages.length - 1];
    const isLoadingUpdate = lastMessage && lastMessage.role !== 'assistant';
    setIsLoading(isLoadingUpdate);
    onLoadingChange(isLoadingUpdate);
  }, [messages, onLoadingChange]); // Fügen Sie onLoadingChange hier hinzu


  const renderMessage = (item, index) => {

    const filteredContent = removeSourceReferences(item.content);


    if (!item.content) {
      // Nichts rendern, wenn der Inhalt leer ist
      return null;
    }

    if (item.type === 'chart') {
      // Angenommen, item.content ist ein String, der das JSON-Objekt enthält
      const contentObj = JSON.parse(item.content);

      // Extrahiere chartType, data und chartConfig direkt aus contentObj
      const chartType = contentObj.chart_type;
      const data = JSON.parse(contentObj.data); // Stelle sicher, dass dies ein Array von Objekten ist
      const chartConfig = JSON.parse(contentObj.chart_config); // Stelle sicher, dass dies ein Objekt ist

      return (
        <List.Item key={index} className="messageListItem">
          <ChartComponent data={data} chartType={chartType} chartConfig={chartConfig} />
        </List.Item>
      );
    }
    else if (item.type === 'form') {
      return (
        <List.Item key={index} className="messageListItem">
          <Form
            formContent={JSON.parse(item.content)}
            messageId={item.id}
            updateMessageContent={updateMessageContent} // Hier übergeben Sie die Funktion als Prop
          />
        </List.Item>
      );
    } else if (item.type === 'file') {
      const downloadUrl = downloadUrls[item.id];
      const fileName = item.content.split('/').pop(); // Extrahiere den Dateinamen aus dem Pfad

      // Prüfe, ob es sich um ein Bild handelt
      if (isImageFile(fileName)) {
        return (
          <List.Item>
            <Image
              src={downloadUrl}
              alt={fileName}
            />
          </List.Item>
        );
      }

      // Für Nicht-Bild-Dateien
      return (
        <List.Item className='downloadItem'>
          <List.Item.Meta
            title={<Typography.Text>{fileName}</Typography.Text>}
            description="Klicke auf den Button, um die Datei herunterzuladen."
          />
          <Tooltip title="Download">
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              href={downloadUrl}
              target="_blank"
              download
            >
              Download
            </Button>
          </Tooltip>
        </List.Item>
      );
    } else if (item.role === 'system') {
  
      return (
        <List.Item key={index} className="messageListItem">
         <div className="messageContentSystem">
            <ReactMarkdown remarkPlugins={[gfm]}>{item.content}</ReactMarkdown>
          </div>
        </List.Item>
      );
    } else {
      return (
        <List.Item key={index} className="messageListItem">
          <div className={`messageContent${item.role === 'user' ? 'User' : item.role === 'assistant' ? 'Robot' : ''}`}>
            {item.role === 'user'
              ? <UserOutlined className="chatIconUser" />
              : item.role === 'assistant'
                ? <img src={azunaLogo} className="chatIconAssistant" alt="Azuna Logo" />
                : null}
            <div className="messageContentBody">
              <ReactMarkdown
                remarkPlugins={[gfm]}
                components={{
                  // Anpassung des "a" (Link) Elements
                  a: ({node, href, children, ...props}) => {
                    // Wenn kein HREF vorhanden ist, zeige nichts an
                    if (!href) {
                      return null; // Nichts rendern, wenn kein href vorhanden ist
                    }
                    // Für gültige Links, füge "rel" und "target" hinzu, um die Sicherheit zu verbessern
                    return <a href={href} {...props} rel="noopener noreferrer" target="_blank">{children}</a>;
                  }
                }}
              >
                {filteredContent}
              </ReactMarkdown>
            </div>
          </div>
          {index === messages.length - 1 && <div ref={bottomListRef} />}
        </List.Item>
      );      
    }

  };

  // Benutzerdefinierte "No Data" Anzeige mit einer Klasse
  const renderNoData = () => (
    <div className="noDataContainer">
      <p className="noDataText">Wie kann ich heute helfen?</p>
    </div>
  );


  return (
    <List
      itemLayout="horizontal"
      dataSource={messages}
      className="messageList"
      renderItem={renderMessage}
    >
      {isLoading && (
        <div className="loadingSpinnerContainer">
          <Spin size="large" />
        </div>
      )}
      {!isLoading && messages.length === 0 && renderNoData()}
    </List>
  );
}

export default MessageList;
